<template>
  <a-form :form="form" @submit.prevent="submit">
    <a-row>
      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" :label="$t('form.first_name')">
          <a-input
            v-decorator="['first_name', {
              rules: [
                {
                  required: true,
                  message: 'Nama Depan wajib diisi',
                },
                {
                  pattern: new RegExp(/^([a-zA-Z .]){3,50}$/, ''),
                  message: 'Nama depan harus lebih dari 3 karakter dan kurang dari 50 karakter'
                }
              ],
            }]"
            :placeholder="$t('form.first_name')"
            class="input-basic"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" :label="$t('form.last_name')">
          <a-input
            v-decorator="['last_name', {
              rules: [
                {
                  required: true,
                  message: 'Nama Belakang wajib diisi',
                },
                {
                  pattern: new RegExp(/^([a-zA-Z .]){3,50}$/, ''),
                  message: 'Nama belakang harus lebih dari 3 karakter dan kurang dari 50 karakter'
                }
              ],
            }]"
            :placeholder="$t('form.last_name')"
            class="input-basic"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" :label="$t('form.phone')">
          <a-input
            v-decorator="['phone', {
              rules: [
                {
                  required: true,
                  message: 'Nomor Handphone wajib diisi',
                },
                {
                  pattern: new RegExp(/^([0-9 +-]){5,25}$/, ''),
                  message: 'Nomor Handphone tidak sesuai format'
                }
              ],
            }]"
            :placeholder="$t('form.phone')"
            class="input-basic"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" :label="`${$t('form.email')} (Pastikan email anda aktif)`">
          <a-input
            type="email"
            v-decorator="['email', {
              rules: [
                {
                  required: isGuest ? true : false,
                  message: 'Untuk pemesanan Guest, wajib diisi untuk tracking order. ',
                },
                {
                  pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, ''),
                  message: 'Email tidak sesuai format. '
                },
                {
                  validator: (role, value, callback) => {
                    if (value?.length > 0 && (value?.length < 3 || value?.length > 50)) {
                      callback('Email harus lebih dari 3 karakter dan kurang dari 50 karakter. ')
                    } else {
                      callback()
                    }
                  },
                }
              ],
            }]"
            :placeholder="$t('form.email') + ` ${!isGuest ? '(Optional)' : ''}`"
            class="input-basic"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" :label="$t('form.address')">
          <a-input
            v-decorator="['address1', {
              rules: [
                {
                  required: true,
                  message: 'Alamat wajib diisi',
                },
                {
                  validator: (role, value, callback) => {
                    if (value?.length < 10 || value?.length > 255) {
                      callback('Alamat harus lebih dari 10 karakter dan kurang dari 255 karakter')
                    } else {
                      callback()
                    }
                  },
                }
              ],
            }]"
            :placeholder="$t('form.address')"
            class="input-basic"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" :label="`${$t('form.address')} 2 - Optional`">
          <a-input
            v-decorator="['address2', {
              rules: [
                {
                  validator: (role, value, callback) => {
                    if (value?.length > 0 && (value?.length < 10 || value?.length > 255)) {
                      callback('Alamat harus lebih dari 10 karakter dan kurang dari 255 karakter')
                    } else {
                      callback()
                    }
                  },
                }
              ],
            }]"
            :placeholder="$t('form.address')"
            class="input-basic"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" :label="$t('form.country')">
          <a-select
            show-search
            v-decorator="['country', {
              rules: [{
                required: true,
                message: 'Negara wajib diisi',
              }],
              initialValue: initialValueCountry && initialValueCountry,
            }]"
            :placeholder="$t('form.country')"
            @change="handleSelectCountry"
            class="input-basic"
          >
            <a-select-option
              v-for="(item, index) in countryList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" :label="$t('form.city')">
          <a-select
            show-search
            v-decorator="['city', {
              rules: [{
                required: true,
                message: 'Kabupaten/Kota - Provinsi wajib diisi',
              }],
            }]"
            :placeholder="$t('form.city')"
            @change="handleSelectCity"
            class="input-basic"
          >
            <a-select-option
              v-for="(item, index) in cityList"
              :value="item.label"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" :label="$t('form.area')">
          <a-select
            show-search
            v-decorator="['area', {
              rules: [{
                required: true,
                message: 'Desa/Kelurahan - Kecamatan wajib diisi',
              }],
            }]"
            :placeholder="$t('form.area')"
            @change="handleSelectArea"
            class="input-basic"
          >
            <a-select-option
              v-for="(item, index) in areaList"
              :value="item.label"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-form-item class="input-form" :label="$t('form.postal_code')">
          <a-input
            v-decorator="['code_pos', {
              rules: [
                {
                  required: true,
                  message: 'Kode POS wajib diisi',
                },
                {
                  validator: (role, value, callback) => {
                    if (value?.length < 5 || value?.length > 20) {
                      callback('Kode POS harus lebih dari 5 karakter dan kurang dari 20 karakter. ')
                    } else {
                      callback()
                    }
                  },
                }
              ],
            }]"
            :placeholder="$t('form.postal_code')"
            class="input-basic"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="24" :md="24">
        <a-button
          type="primary"
          html-type="submit"
          block
          class="mt-3"
          style="
            background: #000000 !important;
            color: #FFFFFF !important;
            border: 1px solid #000000 !important;
          "
        >
          <div v-if="!loading"> Simpan dan Gunakan Alamat </div>
          <div v-if="loading"> <a-icon type="loading" /> </div>
        </a-button>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
export default {
  name: 'address-form',
  props: {
    isShowCancelButton: {
      type: Boolean,
      default: () => true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    isGuest: {
      type: Boolean,
      default: () => false,
    },
    isVisible: {
      type: Boolean,
      default: () => false,
    },
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
      countryList: [],
      cityList: [],
      areaList: [],
      initialValueCountry: null,
    }
  },
  watch: {
    isVisible(value) {
      if (value === true) {
        this.form = this.$form.createForm(this)
      }
    },
  },
  methods: {
    submit () {
      this.form.validateFieldsAndScroll((err, values) => {
        if (err) return

        this.$emit('addAddress', values)
        this.form = this.$form.createForm(this)
      })
    },
    fetchRegionCountry () {
      this.$store.dispatch('data/GETREGIONCOUNTRY', this.$store.state.app.vendor_hashid)
        .then((data) => {
          this.countryList = data.list

          const getIndonesia = data.list.find(item => item.label.toLowerCase() === 'indonesia')

          if (getIndonesia) {
            this.handleSelectCountry(getIndonesia.value)
            this.initialValueCountry = getIndonesia.value
          }
        })
    },
    fetchAreaList (parentId, param) {
      this.$store.dispatch('data/GETAREALLIST', {
        parentId,
      })
        .then((data) => {
          switch (param) {
            case 'city':
              this.cityList = data.list
              break

            case 'area':
              this.areaList = data.list
              break

            default:
              break
          }
        })
    },
    handleSelectCountry (e) {
      this.fetchAreaList(e, 'city')
    },
    handleSelectCity (e) {
      const key = this.cityList.find(item => item.label === e)
      this.fetchAreaList(key.value, 'area')
    },
    handleSelectArea (e) {
      const findArea = this.areaList.find(item => item.label === e)
      this.form.setFieldsValue({
        code_pos: findArea.postcode,
      })
    },
  },
  created () {
    this.fetchRegionCountry()
  },
}
</script>

<style>
  .input-basic::placeholder {
    color: #E3E3E3!important;
  }
  .input-form {
    margin: 10px 0!important;
  }
</style>
