<template>
  <a-modal
    v-model="isModal"
    title="Daftar Alamat"
    :footer="null"
    :closable="true"
    @cancel="handleCancel"
  >
    <div :span="24">
      <div class="modal-address-title">Pilih Alamat &nbsp;/&nbsp; <a class="btn-new-address" @click="handleAddAdress">{{ titleAddAddress ?? '' }}</a></div>
      <div class="address-list">
        <div v-for="(item, index) in listAddress" :key="index">
          <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-between" style="gap: 15px">
              <a-checkbox
                :checked="selectedCurrentAddress === item.id"
                @click="(selectedCurrentAddress = item.id)"
              />
              <div class="w-100">
                <div>
                  {{ item.first_name + ' ' + item.last_name }}
                  <span class="selected-address" v-if="selectedAddress && selectedAddress?.id === item.id">(Alamat Terpilih)</span>
                </div>
                <div class="address-phone">{{ item.phone }}</div>
                <div class="address-full">
                  {{ item?.address_line1 ? `${item.address_line1}, ` : '' }}
                  {{ item?.address_line2 ? `${item.address_line2}, ` : '' }}
                  {{ [item.sub_district, item.district].join(', ') }}{{ ' '}}, {{ ' '}}
                  {{
                    [
                      item.city,
                      item.province,
                      item.country,
                    ].join(', ')
                  }}{{ ' '}}
                </div>
              </div>
            </div>
            <div class="edit-btn">
              <a><EditIcon /></a>
            </div>
          </div>
          <div class="border-space">
            <a-divider />
          </div>
        </div>
      </div>
      <div class="mt-3">
        <a-button
          class="mt-3 btn-save-address"
          @click="handleSelectAddress(selectedCurrentAddress)"
        >
          Simpan
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import EditIcon from '@/components/Icons/Edit.vue'

export default {
  name: 'list-address',
  components: {
    EditIcon,
  },
  props: ['isModal', 'listAddress', 'selectedAddress', 'titleAddAddress'],
  data() {
    return {
      selectedCurrentAddress: null,
    }
  },
  watch: {
    selectedAddress(value) {
      this.selectedCurrentAddress = value?.id || null
    },
  },
  methods: {
    handleSelectAddress(key) {
      this.$emit('emitSelectAddress', { key })
      this.$emit('emitModalAddressList', false)
    },
    handleCancel() {
      this.$emit('emitModalAddressList', false)
      this.selectedCurrentAddress = (this.selectedAddress && this.selectedAddress?.id) || null
    },
    handleAddAdress() {
      this.$emit('emitHandleAddress')
    },
  },
  mounted() {
    this.selectedCurrentAddress = (this.selectedAddress && this.selectedAddress?.id) || null
  },
}
</script>
<style>
  .btn-new-address {
    color: #43A6E4;
  }
  .modal-address-title {
    font-weight: 400!important;
    margin-bottom: 30px;
  }
  .selected-address {
    color: #777777;
  }
  .address-phone {
    font-size: 12px;
    margin: 10px 0;
  }
  .address-full {
    color: #767676;
    font-size: 14px;
    max-width: 300px;
  }
  .border-space {
    padding: 0 150px;
  }
  .address-list {
    height: 400px;
    overflow: auto;
  }
  .btn-save-address {
    width: 100%;
    background: #000000 !important;
    color: #FFFFFF !important;
    border: 1px solid #000000 !important;
  }
  .edit-btn {
    cursor: pointer;
    display: none;
  }
</style>
