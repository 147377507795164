<template>
  <a-row class="checkout-billing">
    <a-row :span="24" class="checkout-billing__title d-flex align-items-center px-4">
      <a-col :class="!isMobileView ? 'w-50' :'w-100'">
        <div>
          {{ $t('purchase.checkout.billing_detail') }}
        </div>
      </a-col>
      <a-col style="justify-content: right: width: 100%; place-content: flex-end;"
        class="checkout-billing__button d-flex align-items-center">
        <div
          v-if="!$store.getters['user/isGuest']"
          class="another-address d-flex align-items-center justify-content-center mr-2 px-2"
          style="width: fit-content;"
          @click="e => handleClickSelectAnotherAddress(e)"
        >
          <span class="d-flex align-items-center justify-content-center">
            Pilih Alamat Lain
          </span>
        </div>
        <div
          v-else
          class="another-address d-flex align-items-center justify-content-center mr-2 px-2"
          :style="`width: fit-content;`"
          @click="() => {
            modalVisible = true
          }"
        >
          <span class="d-flex align-items-center justify-content-center">
            Tambah Alamat
          </span>
        </div>
      </a-col>
    </a-row>

    <a-col :span="24" class="checkout-billing__content px-4 py-2">
      <input type="checkbox" :checked="checked" @change="handleChecked" class="default-checkbox mr-2" />
      <span @click="handleChecked" style="cursor: pointer;">{{ $t('purchase.checkout.use_shipping_address') }}</span>
    </a-col>

    <a-col :span="24" class="checkout-billing__content mb-1 px-4 py-2"
      v-if="selectedBillingAddress">
      <div class="name wrap-text">
        <b>{{ selectedBillingAddress.first_name + ' ' + selectedBillingAddress.last_name }}</b> <span class="gray-title">{{ selectedBillingAddress.phone }}</span>
      </div>
      <div class="gray-title wrap-text">
        {{ selectedBillingAddress?.address_line1 ? `${selectedBillingAddress.address_line1}, ` : '' }}
        {{ selectedBillingAddress?.address_line2 ? `${selectedBillingAddress.address_line2}, ` : '' }}
        {{
          [
            selectedBillingAddress.sub_district,
            selectedBillingAddress.district,
            selectedBillingAddress.city,
            selectedBillingAddress.province,
            selectedBillingAddress.country,
            selectedBillingAddress.postal_code,
          ].join(', ')
        }}{{ ' '}}
      </div>
    </a-col>

    <a-modal
      v-model="modalVisible"
      title="Tambah Alamat Penagihan"
      :footer="null"
      :closable="true"
      @cancel="handleCancel"
    >
      <div :span="24" class="checkout-billing__content mb-1 px-2 py-2">
        <AddressForm :isShowCancelButton="selectedBillingAddress ? true : false" @handleCancelButton="handleCancelButton"
          @addAddress="addAddress" :loading="loading" :isGuest="isGuest" :isVisible="modalVisible" ref="billingAddressForm" />
      </div>
    </a-modal>

    <AddressList
      :isModal="isModal"
      :listAddress="listAvailableBillingAddress"
      :selectedAddress="selectedBillingAddress"
      :titleAddAddress="'Tambah Alamat Penagihan Baru'"
      @emitModalAddressList="emitModalAddressList"
      @emitSelectAddress="handleSelectAddress"
      @emitHandleAddress="(modalVisible = true)"
    />
  </a-row>
</template>

<script>
import AddressForm from '@/components/Address/Form/index.vue'
import AddressList from '@/components/Address/List/index.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    AddressForm,
    AddressList,
  },
  data: function () {
    return {
      isCreateNewAddress: false,
      loading: false,
      checked: false,
      temp: [],
      modalVisible: false,
      isModal: false,
    }
  },
  props: {
    selectedBillingAddress: {
      type: Object,
      default: () => null,
    },
    listAvailableBillingAddress: {
      type: Array,
      default: () => [],
    },
    selectedShippingAddress: {
      type: Object,
      default: () => [],
    },
    selectedShippingType: {
      type: String,
      default: () => 'pickup',
    },
  },
  watch: {
    selectedShippingAddress: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (this.checked) {
          this.selectedBillingAddress = this.selectedShippingAddress
          this.$emit('setSelectedBillingAddress', this.selectedBillingAddress)
        }
      },
    },
  },
  computed: {
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
    ...mapGetters('user', ['isGuest']),
  },
  methods: {
    handleCancel() {
      this.modalVisible = false
    },
    emitModalAddressList() {
      this.isModal = false
    },
    handleClickSelectAnotherAddress(e) {
      this.isModal = true
      e.preventDefault()

      this.$emit('fetchBillingAddress', { addr_key: 'all' })
    },
    handleSelectAddress({ key }) {
      const selectedAddress = this.listAvailableBillingAddress.find(item => item.id === key)
      this.$emit('setSelectedBillingAddress', selectedAddress)
    },
    handleCancelButton() {
      this.isCreateNewAddress = false
      this.modalVisible = false
    },
    getAreaId(label) {
      const data = this.$refs.billingAddressForm.$data.areaList.find(item => item.label === label)

      return data ? data.value : null
    },
    getCityId(label) {
      const data = this.$refs.billingAddressForm.$data.cityList.find(item => item.label === label)

      return data ? data.value : null
    },
    getCountryLabel(value) {
      const data = this.$refs.billingAddressForm.$data.countryList.find(item => item.value === value)

      return data ? data.label : null
    },
    addAddress(values) {
      const tempCityProvince = values.city.split(' - ')
      const tempDistrictSubdistrict = values.area.split(' - ')
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        address_line1: values.address1,
        address_line2: values.address2,
        area_id: +this.getAreaId(values.area),
        district: tempDistrictSubdistrict?.length > 0 ? tempDistrictSubdistrict[1] : null,
        sub_district: tempDistrictSubdistrict?.length > 0 ? tempDistrictSubdistrict[0] : null,
        city_id: +this.getCityId(values.city),
        city: tempCityProvince?.length > 0 ? tempCityProvince[0] : null,
        province: tempCityProvince?.length > 0 ? tempCityProvince[1] : null,
        postal_code: values.code_pos,
        country_id: +values.country,
        country: this.getCountryLabel(values.country),
      }

      // this.$emit('setSelectedBillingAddress', data) // TODO: must be deleted before live production
      // this.isCreateNewAddress = false // TODO: must be deleted before live production

      this.loading = true

      if (this.$store.getters['user/isGuest']) {
        this.$emit('setSelectedBillingAddress', data)
        this.isCreateNewAddress = false
        this.modalVisible = false
        this.loading = false
        this.isModal = false
      } else {
        this.$store.dispatch('purchase/ADDBILLINGADDRESS', {
          ...data,
          channel_id: this.$store.state.user.user_data.channel_id,
        })
          .then(({ data }) => {
            this.$emit('setSelectedBillingAddress', data)
            this.isCreateNewAddress = false
            this.modalVisible = false
            this.isModal = false
          })
          .catch((err) => {
            console.error(err)
            this.$notification.error({
              message: 'add new address failed',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleChecked() {
      this.checked = !this.checked
      if (this.checked !== true) {
        this.selectedBillingAddress = this.temp
        this.$emit('setSelectedBillingAddress', this.temp)
      } else {
        this.temp = this.selectedBillingAddress
        this.selectedBillingAddress = this.selectedShippingAddress
        this.$emit('setSelectedBillingAddress', this.selectedBillingAddress)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.w-50 {
  width: 50% !important;
}
.w-100 {
  width: 100% !important;
}
.checkout-billing {
  margin-bottom: 1.5rem;
  border: 0.716px solid var(--biz-primary-20);
  border-radius: 10px;

  &__title {
    background: var(--biz-brand-base);
    height: 40px;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: var(--biz-base-100);
    padding: 35px;
    border-radius: 10px;

    @media (max-width: 600px) {
      font-size: 14px;
      padding-bottom: 20px;
    }
  }

  &__content {
    background: var(--biz-brand-base);
    height: auto;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: var(--biz-base-100);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__button {
    background: var(--biz-brand-base);
    width: 100%;
    height: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 14px;

    @media (max-width:600px) {
      font-size: 12px;
    }

    div {
      height: 35px;
      box-sizing: border-box;
      border: 1px solid var(--biz-base-100);
      border-radius: 6px;
      color: var(--biz-base-100);
      cursor: pointer;
      background: var(--biz-brand-base);
      padding: 0 10px;
    }

    div:hover {
      background: var(--biz-brand-base);
      color: var(--biz-base-100);
    }
  }
}
.gray-title {
  color: #767676;
}
.default-checkbox {
  accent-color: var(--biz-brand);
  color: var(--biz-brand-base);
  transform: scale(1.2);
}
.wrap-text {
  overflow-wrap: break-word;
}
</style>
