<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
    <path
      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
      fill="#F3F3F4" />
    <path
      d="M36.3836 61.668C35.2524 60.5246 34.6868 59.144 34.6868 57.5262C34.6868 55.9083 35.2524 54.5338 36.3836 53.4025C37.5271 52.2591 38.9077 51.6874 40.5255 51.6874C42.1433 51.6874 43.5179 52.2591 44.6492 53.4025C45.7926 54.5338 46.3643 55.9083 46.3643 57.5262C46.3643 59.144 45.7926 60.5246 44.6492 61.668C43.5179 62.7993 42.1433 63.3649 40.5255 63.3649C38.9077 63.3649 37.5271 62.7993 36.3836 61.668ZM36.3836 18.005C37.5271 16.8616 38.9077 16.2899 40.5255 16.2899C42.1433 16.2899 43.5179 16.8616 44.6492 18.005C45.7926 19.1363 46.3643 20.5108 46.3643 22.1286C46.3643 24.7074 45.9081 28.3141 44.9958 32.9486C44.0835 37.571 43.1043 41.7493 42.0582 45.4837H38.9928C37.9467 41.7493 36.9675 37.571 36.0552 32.9486C35.1429 28.3141 34.6868 24.7074 34.6868 22.1286C34.6868 20.5108 35.2524 19.1363 36.3836 18.005Z"
      :fill="color || '#F5831F'" />
  </svg>
</template>

<script>
export default {
  name: 'warning',
  props: ['color'],
}
</script>
