export function getPromotionPerTransaction(total, discount) {
  let price = total
  const filterItem = discount?.filter(v => {
    return v.type === 'transaction'
  })
  for (let i = 0; i < filterItem.length; i++) {
    // 1. transaction -> distributor -> pronas -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 1.a. transaction -> distributor -> pronas -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 1.b. transaction -> distributor -> pronas -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 2. transaction -> distributor -> proloc -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 2.a. transaction -> distributor -> proloc -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 2.b. transaction -> distributor -> proloc -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 3. transaction -> distributor -> prodis -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 3.a. transaction -> distributor -> prodis -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 3.b. transaction -> distributor -> prodis -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 4. transaction -> principal -> pronas -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 4.a. transaction -> principal -> pronas -> tier 2
    if (filterItem.length > 0 &&
    filterItem[i].type === 'transaction' &&
    filterItem[i].is_principal === true &&
    filterItem[i].attribute?.toLowerCase() === 'pronas' &&
    filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 4.b. transaction -> principal -> pronas -> tier 3
    if (filterItem.length > 0 &&
    filterItem[i].type === 'transaction' &&
    filterItem[i].is_principal === true &&
    filterItem[i].attribute?.toLowerCase() === 'pronas' &&
    filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 5. transaction -> principal -> proloc -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 5.a. transaction -> principal -> proloc -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 5.b. transaction -> principal -> proloc -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 6. transaction -> principal -> prodis -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 6.a. transaction -> principal -> prodis -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 6.b. transaction -> principal -> prodis -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
  }
  return price
}

export function getmappingTransaction(total, discount, id) {
  let price = total
  const result = []
  const filterItem = discount.filter(v => {
    return v.type === 'transaction'
  })
  for (let i = 0; i < filterItem.length; i++) {
    // 1. transaction -> distributor -> pronas -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 1.a. transaction -> distributor -> pronas -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 1.b. transaction -> distributor -> pronas -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 2. transaction -> distributor -> proloc -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 2.a. transaction -> distributor -> proloc -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 2.b. transaction -> distributor -> proloc -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 3. transaction -> distributor -> prodis -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 3.a. transaction -> distributor -> prodis -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 3.b. transaction -> distributor -> prodis -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 4. transaction -> principal -> pronas -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 4.a. transaction -> principal -> pronas -> tier 2
    if (filterItem.length > 0 &&
    filterItem[i].type === 'transaction' &&
    filterItem[i].is_principal === true &&
    filterItem[i].attribute?.toLowerCase() === 'pronas' &&
    filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 4.b. transaction -> principal -> pronas -> tier 3
    if (filterItem.length > 0 &&
    filterItem[i].type === 'transaction' &&
    filterItem[i].is_principal === true &&
    filterItem[i].attribute?.toLowerCase() === 'pronas' &&
    filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 5. transaction -> principal -> proloc -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 5.a. transaction -> principal -> proloc -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 5.b. transaction -> principal -> proloc -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      ['prolok', 'proloc'].includes(filterItem[i].attribute?.toLowerCase()) &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 6. transaction -> principal -> prodis -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 6.a. transaction -> principal -> prodis -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
    // 6.b. transaction -> principal -> prodis -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'transaction' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].max_value !== 0 && discount > filterItem[i].gift[j].max_value ? filterItem[i].gift[j].max_value : discount,
            price: price,
          }
          result.push(data)
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - filterItem[i].gift[j].value
          const data = {
            promotion_id: filterItem[i].promotion_id,
            discount: filterItem[i].gift[j].value,
            price: price,
          }
          result.push(data)
        }
      }
    }
  }
  return result
}
