/* eslint-disable */

export const getIdWarehouse = (data = []) => {
  const hash = {}

  const warehouse_ids = data.map(item => {
    return item.warehouse_id
  }).filter((section) => {
    if (!hash[section]) {
      hash[section] = true
      return true
    }

    return false
  })

  return warehouse_ids
}

export const joinWarehouseCart = (warehouses = [], carts = []) => {
  const result = warehouses.map((item) => {
    const availableCart = carts.filter((value) => value.warehouse_id === item.id)

    return {
      ...item,
      cart_list: availableCart,
    }
  })
  return result
}
