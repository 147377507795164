var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('a-row',[_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":_vm.$t('form.first_name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['first_name', {
            rules: [
              {
                required: true,
                message: 'Nama Depan wajib diisi',
              },
              {
                pattern: new RegExp(/^([a-zA-Z .]){3,50}$/, ''),
                message: 'Nama depan harus lebih dari 3 karakter dan kurang dari 50 karakter'
              }
            ],
          }]),expression:"['first_name', {\n            rules: [\n              {\n                required: true,\n                message: 'Nama Depan wajib diisi',\n              },\n              {\n                pattern: new RegExp(/^([a-zA-Z .]){3,50}$/, ''),\n                message: 'Nama depan harus lebih dari 3 karakter dan kurang dari 50 karakter'\n              }\n            ],\n          }]"}],staticClass:"input-basic",attrs:{"placeholder":_vm.$t('form.first_name')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":_vm.$t('form.last_name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['last_name', {
            rules: [
              {
                required: true,
                message: 'Nama Belakang wajib diisi',
              },
              {
                pattern: new RegExp(/^([a-zA-Z .]){3,50}$/, ''),
                message: 'Nama belakang harus lebih dari 3 karakter dan kurang dari 50 karakter'
              }
            ],
          }]),expression:"['last_name', {\n            rules: [\n              {\n                required: true,\n                message: 'Nama Belakang wajib diisi',\n              },\n              {\n                pattern: new RegExp(/^([a-zA-Z .]){3,50}$/, ''),\n                message: 'Nama belakang harus lebih dari 3 karakter dan kurang dari 50 karakter'\n              }\n            ],\n          }]"}],staticClass:"input-basic",attrs:{"placeholder":_vm.$t('form.last_name')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":_vm.$t('form.phone')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone', {
            rules: [
              {
                required: true,
                message: 'Nomor Handphone wajib diisi',
              },
              {
                pattern: new RegExp(/^([0-9 +-]){5,25}$/, ''),
                message: 'Nomor Handphone tidak sesuai format'
              }
            ],
          }]),expression:"['phone', {\n            rules: [\n              {\n                required: true,\n                message: 'Nomor Handphone wajib diisi',\n              },\n              {\n                pattern: new RegExp(/^([0-9 +-]){5,25}$/, ''),\n                message: 'Nomor Handphone tidak sesuai format'\n              }\n            ],\n          }]"}],staticClass:"input-basic",attrs:{"placeholder":_vm.$t('form.phone')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":`${_vm.$t('form.email')} (Pastikan email anda aktif)`}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
            rules: [
              {
                required: _vm.isGuest ? true : false,
                message: 'Untuk pemesanan Guest, wajib diisi untuk tracking order. ',
              },
              {
                pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, ''),
                message: 'Email tidak sesuai format. '
              },
              {
                validator: (role, value, callback) => {
                  if (value?.length > 0 && (value?.length < 3 || value?.length > 50)) {
                    callback('Email harus lebih dari 3 karakter dan kurang dari 50 karakter. ')
                  } else {
                    callback()
                  }
                },
              }
            ],
          }]),expression:"['email', {\n            rules: [\n              {\n                required: isGuest ? true : false,\n                message: 'Untuk pemesanan Guest, wajib diisi untuk tracking order. ',\n              },\n              {\n                pattern: new RegExp(/^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$/, ''),\n                message: 'Email tidak sesuai format. '\n              },\n              {\n                validator: (role, value, callback) => {\n                  if (value?.length > 0 && (value?.length < 3 || value?.length > 50)) {\n                    callback('Email harus lebih dari 3 karakter dan kurang dari 50 karakter. ')\n                  } else {\n                    callback()\n                  }\n                },\n              }\n            ],\n          }]"}],staticClass:"input-basic",attrs:{"type":"email","placeholder":_vm.$t('form.email') + ` ${!_vm.isGuest ? '(Optional)' : ''}`}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":_vm.$t('form.address')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address1', {
            rules: [
              {
                required: true,
                message: 'Alamat wajib diisi',
              },
              {
                validator: (role, value, callback) => {
                  if (value?.length < 10 || value?.length > 255) {
                    callback('Alamat harus lebih dari 10 karakter dan kurang dari 255 karakter')
                  } else {
                    callback()
                  }
                },
              }
            ],
          }]),expression:"['address1', {\n            rules: [\n              {\n                required: true,\n                message: 'Alamat wajib diisi',\n              },\n              {\n                validator: (role, value, callback) => {\n                  if (value?.length < 10 || value?.length > 255) {\n                    callback('Alamat harus lebih dari 10 karakter dan kurang dari 255 karakter')\n                  } else {\n                    callback()\n                  }\n                },\n              }\n            ],\n          }]"}],staticClass:"input-basic",attrs:{"placeholder":_vm.$t('form.address')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":`${_vm.$t('form.address')} 2 - Optional`}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['address2', {
            rules: [
              {
                validator: (role, value, callback) => {
                  if (value?.length > 0 && (value?.length < 10 || value?.length > 255)) {
                    callback('Alamat harus lebih dari 10 karakter dan kurang dari 255 karakter')
                  } else {
                    callback()
                  }
                },
              }
            ],
          }]),expression:"['address2', {\n            rules: [\n              {\n                validator: (role, value, callback) => {\n                  if (value?.length > 0 && (value?.length < 10 || value?.length > 255)) {\n                    callback('Alamat harus lebih dari 10 karakter dan kurang dari 255 karakter')\n                  } else {\n                    callback()\n                  }\n                },\n              }\n            ],\n          }]"}],staticClass:"input-basic",attrs:{"placeholder":_vm.$t('form.address')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":_vm.$t('form.country')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['country', {
            rules: [{
              required: true,
              message: 'Negara wajib diisi',
            }],
            initialValue: _vm.initialValueCountry && _vm.initialValueCountry,
          }]),expression:"['country', {\n            rules: [{\n              required: true,\n              message: 'Negara wajib diisi',\n            }],\n            initialValue: initialValueCountry && initialValueCountry,\n          }]"}],staticClass:"input-basic",attrs:{"show-search":"","placeholder":_vm.$t('form.country')},on:{"change":_vm.handleSelectCountry}},_vm._l((_vm.countryList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":_vm.$t('form.city')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['city', {
            rules: [{
              required: true,
              message: 'Kabupaten/Kota - Provinsi wajib diisi',
            }],
          }]),expression:"['city', {\n            rules: [{\n              required: true,\n              message: 'Kabupaten/Kota - Provinsi wajib diisi',\n            }],\n          }]"}],staticClass:"input-basic",attrs:{"show-search":"","placeholder":_vm.$t('form.city')},on:{"change":_vm.handleSelectCity}},_vm._l((_vm.cityList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":_vm.$t('form.area')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['area', {
            rules: [{
              required: true,
              message: 'Desa/Kelurahan - Kecamatan wajib diisi',
            }],
          }]),expression:"['area', {\n            rules: [{\n              required: true,\n              message: 'Desa/Kelurahan - Kecamatan wajib diisi',\n            }],\n          }]"}],staticClass:"input-basic",attrs:{"show-search":"","placeholder":_vm.$t('form.area')},on:{"change":_vm.handleSelectArea}},_vm._l((_vm.areaList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-form-item',{staticClass:"input-form",attrs:{"label":_vm.$t('form.postal_code')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code_pos', {
            rules: [
              {
                required: true,
                message: 'Kode POS wajib diisi',
              },
              {
                validator: (role, value, callback) => {
                  if (value?.length < 5 || value?.length > 20) {
                    callback('Kode POS harus lebih dari 5 karakter dan kurang dari 20 karakter. ')
                  } else {
                    callback()
                  }
                },
              }
            ],
          }]),expression:"['code_pos', {\n            rules: [\n              {\n                required: true,\n                message: 'Kode POS wajib diisi',\n              },\n              {\n                validator: (role, value, callback) => {\n                  if (value?.length < 5 || value?.length > 20) {\n                    callback('Kode POS harus lebih dari 5 karakter dan kurang dari 20 karakter. ')\n                  } else {\n                    callback()\n                  }\n                },\n              }\n            ],\n          }]"}],staticClass:"input-basic",attrs:{"placeholder":_vm.$t('form.postal_code')}})],1)],1),_c('a-col',{attrs:{"xs":24,"sm":24,"md":24}},[_c('a-button',{staticClass:"mt-3",staticStyle:{"background":"#000000 !important","color":"#FFFFFF !important","border":"1px solid #000000 !important"},attrs:{"type":"primary","html-type":"submit","block":""}},[(!_vm.loading)?_c('div',[_vm._v(" Simpan dan Gunakan Alamat ")]):_vm._e(),(_vm.loading)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}})],1):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }