<template>
  <div>
    <a-row class="checkout-shipping">
      <a-row :span="24" class="checkout-shipping__title d-flex align-items-center px-4">
        <a-col :style="!isMobileView ? 'width: 50%' :'width: 100%'">
          <div> {{ selectedShippingType === 'pickup' ? 'Alamat Pengambilan' : 'Alamat Pengiriman' }} </div>
        </a-col>
        <a-col style="justify-content: right" class="checkout-shipping__button d-flex align-items-center">
          <div
            v-if="!$store.getters['user/isGuest']"
            class="another-address d-flex align-items-center justify-content-center mr-2 px-2"
            :style="`width: fit-content; ${selectedShippingType === 'pickup' ? 'color: #D3D3D3; border-color:#D3D3D3; cursor: not-allowed;' : ''}`"
            @click="e => selectedShippingType !== 'pickup' && handleClickSelectAnotherAddress(e)"
          >
            <span class="d-flex align-items-center justify-content-center">
              Pilih Alamat Lain
            </span>
          </div>
          <div
            v-else
            class="another-address d-flex align-items-center justify-content-center mr-2 px-2"
            :style="`width: fit-content; ${selectedShippingType === 'pickup' ? 'color: #D3D3D3; border-color:#D3D3D3; cursor: not-allowed;' : ''}`"
            @click="() => {
              if (selectedShippingType === 'shipping') {
                modalVisible = true
              }
            }"
          >
            <span class="d-flex align-items-center justify-content-center">
              Tambah Alamat
            </span>
          </div>
        </a-col>
      </a-row>

      <a-col :span="24" class="checkout-shipping__content mb-1 px-4 py-2" v-if="selectedShippingAddress && selectedShippingType !== 'pickup'">
        <div class="name wrap-text">
          <b>{{ selectedShippingAddress.first_name + ' ' + selectedShippingAddress.last_name }}</b> <span class="gray-title">{{ selectedShippingAddress.phone }}</span>
        </div>
        <div class="gray-title wrap-text">
          {{ selectedShippingAddress?.address_line1 ? `${selectedShippingAddress.address_line1}, ` : '' }}
          {{ selectedShippingAddress?.address_line2 ? `${selectedShippingAddress.address_line2}, ` : '' }}
          {{
            [
            selectedShippingAddress.sub_district,
            selectedShippingAddress.district,
            selectedShippingAddress.city,
            selectedShippingAddress.province,
            selectedShippingAddress.country,
            selectedShippingAddress.postal_code,
            ].join(', ')
          }}{{ ' '}}
        </div>
      </a-col>
      <a-col :span="24" class="checkout-shipping__content mb-1 px-4 py-2" v-if="warehouseData && selectedShippingType === 'pickup'">
        <div class="name">
          <b>{{ warehouseData.name }}</b>
        </div>
        <div class="gray-title">
          {{ [warehouseData.address.line1,
            warehouseData.address.line2].join(', ')
          }}
          {{
            [
              warehouseData.address.subDistrict,
              warehouseData.address.district,
              warehouseData.address.city,
              warehouseData.address.province,
              warehouseData.address.country,
            ].join(', ')
          }}{{ ' '}}
        </div>
      </a-col>

      <a-modal
        v-model="modalVisible"
        title="Tambah Alamat Pengiriman"
        :footer="null"
        :closable="true"
        @cancel="handleCancel"
      >
        <div :span="24" class="checkout-shipping__content mb-1 px-2 py-2">
          <AddressForm :isShowCancelButton="selectedShippingAddress ? true : false" @handleCancelButton="handleCancelButton"
            @addAddress="addAddress" :loading="loading" :isGuest="isGuest" :isVisible="modalVisible" ref="shippingAddressForm" />
        </div>
      </a-modal>

      <AddressList
        :isModal="isModal"
        :listAddress="listAvailableShippingAddress"
        :selectedAddress="selectedShippingAddress"
        :titleAddAddress="'Tambah Alamat Pengiriman Baru'"
        @emitModalAddressList="emitModalAddressList"
        @emitSelectAddress="handleSelectAddress"
        @emitHandleAddress="(modalVisible = true)"
      />
    </a-row>
  </div>
</template>

<script>
import AddressForm from '@/components/Address/Form/index.vue'
import AddressList from '@/components/Address/List/index.vue'
import { mapGetters } from 'vuex'
// import { v4 } from 'uuid'

export default {
  components: {
    AddressForm,
    AddressList,
  },
  data: function () {
    return {
      isCreateNewAddress: false,
      loading: false,
      modalVisible: false,
      isModal: false,
    }
  },
  props: {
    selectedShippingAddress: {
      type: Object,
      default: () => null,
    },
    listAvailableShippingAddress: {
      type: Array,
      default: () => [],
    },
    selectedShippingType: {
      type: String,
      default: () => 'pickup',
    },
    warehouseData: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    selectedShippingType(value) {
      if (value === 'pickup') {
        this.isDropshipper = false
        this.$emit('emitIsDropshipper', false)
      }
    },
  },
  computed: {
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
    ...mapGetters('user', ['isGuest']),
  },
  methods: {
    emitModalAddressList() {
      this.isModal = false
    },
    handleCancel() {
      this.modalVisible = false
    },
    handleCancelDropshipper() {
      this.modalVisible = false
    },
    handleClickDropshipper() {
      if (this.isDropshipper === false) {
        this.modalDropshipper = true
      } else {
        this.isDropshipper = !this.isDropshipper
        this.$emit('emitIsDropshipper', !this.isDropshipper)
      }
    },
    handleClickSelectAnotherAddress(e) {
      console.log('handleClickSelectAnotherAddresshandleClickSelectAnotherAddresshandleClickSelectAnotherAddress')
      this.isModal = true
      e.preventDefault()
      this.$emit('fetchShippingAddress', { addr_key: 'all' })
    },
    handleSelectAddress({ key }) {
      const selectedAddress = this.listAvailableShippingAddress.find(item => item.id === key)
      this.$emit('setSelectedShippingAddress', selectedAddress)
    },
    handleCancelButton() {
      this.isCreateNewAddress = false
      this.modalVisible = false
    },
    getAreaId(label) {
      const data = this.$refs.shippingAddressForm.$data.areaList.find(item => item.label === label)

      return data ? data.value : null
    },
    getCityId(label) {
      const data = this.$refs.shippingAddressForm.$data.cityList.find(item => item.label === label)

      return data ? data.value : null
    },
    getCountryLabel(value) {
      const data = this.$refs.shippingAddressForm.$data.countryList.find(item => item.value === value)

      return data ? data.label : null
    },
    saveDropshipper(values) {
      this.dropShipping = values
      this.isDropshipper = true
      this.modalDropshipper = false
      this.$emit('emitIsDropshipper', true)
      this.$emit('emitIsDropShipping', values)
    },
    addAddress(values) {
      const tempCityProvince = values.city.split(' - ')
      const tempDistrictSubdistrict = values.area.split(' - ')
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        address_line1: values.address1,
        address_line2: values.address2,
        area_id: +this.getAreaId(values.area),
        district: tempDistrictSubdistrict?.length > 0 ? tempDistrictSubdistrict[1] : null,
        sub_district: tempDistrictSubdistrict?.length > 0 ? tempDistrictSubdistrict[0] : null,
        city_id: +this.getCityId(values.city),
        city: tempCityProvince?.length > 0 ? tempCityProvince[0] : null,
        province: tempCityProvince?.length > 0 ? tempCityProvince[1] : null,
        postal_code: values.code_pos,
        country_id: +values.country,
        country: this.getCountryLabel(values.country),
      }

      // this.$emit('setSelectedShippingAddress', data) // TODO: must be deleted before live production
      // this.isCreateNewAddress = false // TODO: must be deleted before live production

      this.loading = true
      if (this.$store.getters['user/isGuest']) {
        this.$emit('setSelectedShippingAddress', data)
        this.isCreateNewAddress = false
        this.modalVisible = false
        this.loading = false
      } else {
        this.$store.dispatch('purchase/ADDSHIPPINGADDRESS', {
          ...data,
          channel_id: this.$store.state.user.user_data.channel_id,
        })
          .then(({ data }) => {
            this.$emit('setSelectedShippingAddress', data)
            this.isCreateNewAddress = false
            this.modalVisible = false
            this.isModal = false
          })
          .catch((err) => {
            console.log(err)
            this.$notification.error({
              message: 'add new address failed',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.checkout-shipping {
  margin-bottom: 1rem !important;
  border: 0.716px solid var(--biz-primary-20);
  border-radius: 10px;

  &__title {
    background: var(--biz-brand-base);
    height: 40px;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: var(--biz-base-100);
    padding: 35px;
    border-radius: 10px;

    @media (max-width: 600px) {
      font-size: 14px;
      padding-bottom: 20px;
    }
  }

  &__content {
    background: var(--biz-brand-base);
    height: auto;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: var(--biz-base-100);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__button {
    background: var(--biz-brand-base);
    width: 100%;
    height: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 14px;

    @media (max-width:600px) {
      font-size: 12px;
    }

    div {
      height: 35px;
      box-sizing: border-box;
      border: 1px solid var(--biz-base-100);
      border-radius: 6px;
      color: var(--biz-base-100);
      cursor: pointer;
      background: var(--biz-brand-base);
      padding: 0 10px;
    }

    div:hover {
      background: var(--biz-brand-base);
      color: var(--biz-base-100);
    }
  }
}
.gray-title {
  color: var(--biz-primary-40);
}
.dropshipper-container {
  display: flex;
  gap: 15px;
}
.wrap-text {
  overflow-wrap: break-word;
}
</style>
