export default function getPromotionPerProduct(cartList, discount) {
  let price = cartList.price.selling_price
  const filterItem = discount.filter(v => {
    return v.catalog_id === cartList.item_id
  })
  for (let i = 0; i < filterItem.length; i++) {
    // 1. product -> distributor -> pronas -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 1.a. product -> distributor -> pronas -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 1.b. product -> distributor -> pronas -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 2. product -> distributor -> proloc -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'proloc' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 2.a. product -> distributor -> proloc -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'proloc' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 2.b. product -> distributor -> proloc -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'proloc' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 3. product -> distributor -> prodis -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 3.a. product -> distributor -> prodis -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 3.b. product -> distributor -> prodis -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === false &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 4. product -> principal -> pronas -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 4.a. product -> principal -> pronas -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 4.b. product -> principal -> pronas -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'pronas' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 5. product -> principal -> proloc -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'proloc' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 5.a. product -> principal -> proloc -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'proloc' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 5.b. product -> principal -> proloc -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'proloc' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 6. product -> principal -> prodis -> tier 1
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 1) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 6.a. product -> principal -> prodis -> tier 2
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 2) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
    // 6.b. product -> principal -> prodis -> tier 3
    if (filterItem.length > 0 &&
      filterItem[i].type === 'product' &&
      filterItem[i].is_principal === true &&
      filterItem[i].attribute?.toLowerCase() === 'prodis' &&
      filterItem[i].tier === 3) {
      for (let j = 0; j < filterItem[i].gift.length; j++) {
        if (filterItem[i].gift[j].value_type === 'percentage') {
          const discount = Math.round(price * (filterItem[i].gift[j].value / 100))
          if (discount > filterItem[i].gift[j].max_value) {
            price = price - filterItem[i].gift[j].max_value
          } else {
            price = price - discount
          }
        } else if (filterItem[i].gift[j].value_type === 'fixed') {
          price = price - Number(filterItem[i].gift[j].value)
        }
      }
    }
  }
  if (price < 0) {
    price = 0
  }
  return price
}
